<template>
    <BlogPostLayout :tags="tags" :meta="meta">
        <template v-slot:title>How more downtime drives creativity and innovation</template>

        <template v-slot:excerpt>As more and more companies are pushing creative ways of implementing a more sustainable
            work-life balance model, we’re opting for unlimited vacation. That’s just how we roll.
        </template>

        <template v-slot:content>

            <img class="blog-post-image" src="../../../assets/blog/images/office-chairs.jpg" alt="Superology office">

            <p>Picture this. After working long hours on a particularly intensive project for a month, you find yourself
                drained. You’re constantly tired, meaningless tasks seem insurmountable, and your focus is off. It’s
                obvious you need a break. At least one that’s a bit more meaningful than just a weekend. But your yearly
                vacation time quota is spent, and the mechanics are straightforward – there’s nothing to be done.</p>

            <p>As more and more companies are pushing creative ways of implementing a more sustainable work-life balance
                model, the situation, as hypothesised above, seems outdated and counterproductive. How can you expect
                your people to put new ideas on the table when all they can think about is their couch?
            </p>

            <h2>A more sustainable work-life model is possible</h2>

            <p>At the end of 2022, The Guardian <a
                href="https://www.theguardian.com/business/2022/nov/27/a-hundred-uk-companies-sign-up-for-four-day-week-with-no-loss-of-pay"
                target="_blank">reported</a> that "a hundred UK companies sign up for a four-day week with no loss of
                pay". These are the results of the world’s biggest pilot 4-day scheme under the supervision of the
                Universities of Cambridge and Oxford, Boston College and thinktank Autonomy. "About 95% of the companies
                surveyed", they report, "said productivity had either stayed the same or improved since the
                introduction".</p>

            <p>This is just one model that seems adamant about tackling the "hangover from an earlier economic age".
                Other companies are experimenting with a 6-hour workday with similar results or <a
                    href="https://www.businessnewsdaily.com/15102-summer-fridays-gain-traction.html" target="_blank">introducing</a>
                a "Summer Friday". Some are going even further with unlimited or open vacation policies.</p>


            <h2>What is an unlimited vacation policy, and how does it work? </h2>

            <p>At Superology, we like our people inspired, empowered, and focused on innovation, not error prevention.
                We also rely on "freedom and responsibility", a concept loosely defined in <strong>Reed
                    Hastings</strong>’ and <strong>Erin Meyer</strong>’s book No Rules Rules: Netflix and the Culture of
                Reinvention, as "jazz, not a symphony". It’s a culture built on top talent hell-bent on developing
                phenomenal products, radical organisational transparency, and mutual trust when removing obstacles, such
                are vacation and expenses. "Jazz emphasises individual spontaneity", Reed writes: "The musicians know
                the overall structure of the song but have the freedom to improvise, riffing off one another, creating
                incredible music."</p>

            <blockquote class="quote-solid-line">
                <p class="bold-quote">Unlimited vacation means taking a vacation when I need it, without feeling guilty
                    about using precious vacation days. It has a positive impact on productivity, that is for sure.</p>
                <p>– Hannah Majić, Design Team Lead</p>
            </blockquote>

            <p>Does this mean that every single one of us at Superology can take as much time off as we please? In a
                nutshell, yes.</p>

            <p><i>"For me, unlimited vacation represents a contract between the employer and employee in which the
                concern of a limited resting time if the work is progressing as planned – is eliminated. I'm giving my
                best effort to improve the product we are working on. If I manage to do that, the company doesn't mind
                if I take a few more days off without sacrificing performance"</i>, says Backend Developer <strong>Ivan
                Lesar</strong>. </p>

            <h2>How much vacation is okay? </h2>

            <p>We often get asked if people abuse this policy. We are masters of our days off and the duchesses of
                adventurous getaways, for sure, but we all understand that at the end of the day – the work must be
                done. </p>

            <p>When taking days off, we ask everyone to tick two boxes:</p>

            <ul>
                <li>Are your tasks done?</li>
                <li>Will your teammates drown if you skip ship?</li>
            </ul>

            <p>It’s a personal call, but we always try to keep it real and take the product and the team into
                account. </p>

            <p>It was a game-changer for Design Team Lead <strong>Hannah Majić</strong>: <i>"Unlimited vacation was a
                significant change for me. After more than 17 years of having boundaries when it came to taking a
                vacation, it was a challenge to grasp this new kind of freedom. But once it finally sunk in, it made me
                feel more at ease, and my work-life balance improved greatly. But for a time, the question remained, how
                much vacation is ok? I feel that this ‘unlimited vacation’ rule only works when people have the right
                mindset, knowing how many vacation days they can take without jeopardising their work and obligations.
                It is a balancing act, for sure."</i></p>

            <h2>Follow the numbers </h2>

            <p>To put things in perspective, let’s talk numbers. But first, a note on the sample:</p>

            <ul>
                <li>The cumulative calculations were based on the time span of one year, with the number of employees on
                    October 1st 2021, and October 1st 2022.
                </li>
                <li>The individual calculations were also based on a yearly account – from January 1st to December
                    31st.
                </li>
                <li>Upper management was factored out of the equation to get a clearer perspective on how this policy
                    was used by average employees, not the echelons considered as decision makers.
                </li>
            </ul>

            <p>In October 2021, <strong>there were 62 of us</strong>, and we spent <strong>a total of 798 days vacationing</strong>. In October 2022, <strong>we
                had grown to 95 people</strong> and spent <strong>1911 on vacation</strong>, and the average increased to 20,12 days out of office
                per person. This might not sound like much, but you must factor in that these calculations also include
                people that haven’t been with us that long and haven’t yet started to use their vacation time properly –
                it’s still an average <strong>increase of 56%</strong> from last year.</p>

            <p>Now, most of these absolutes correspond to traditional vacation time periods, such are summer and winter
                holidays, so we took a closer look at off-season periods and found significant increases. From early
                2021 to late spring 2021 (Jan 1st – May 31st), the <strong>maximum number of days off taken was 4</strong> (avg. 0,21).
                The same period <strong>for 2022 shows it was 18</strong> (avg. 3,71). From late summer to late fall 2021 (Sep 1st – Nov
                30th), <strong>we had a maximum of 13 days off</strong> (avg. 1,97), and <strong>in 2022 – it was 17</strong> (avg. 2,35).</p>

            <p>As far as individual numbers go, we took the whole calendar year into account, from January 1st to
                December 31st. Thus, the total maximum number of days a single person used for vacation <strong>in 2021 was 36</strong>.
                In 2022, <strong>that number was 38</strong>. And we had not one, but two people take that amount of vacation days. Also,
                to show that this was more of a trend than a deviation – in 2022, <strong>the runner-up took 37 days off</strong>,
                followed closely by <strong>35 days</strong> for third place.</p>


            <p>One more thing has to be said about these figures: they don’t account for days spent at conferences,
                workshops, seminars, meet-ups or any other form of educational activities that implicate days out of the
                office. We don’t count those as downtime.</p>

            <p>That being said, all these numbers show an undisputable upwards trend for the average Joe and Jane at
                Superology. And we think that’s beautiful. Jokes aside, it is quite an admirable feat to quantify
                something that’s sometimes considered a stunt or a ploy to attract talent.</p>

            <h2>A system built on trust and autonomy, not control </h2>

            <p>We believe people can manage themselves, and unlimited vacation is a true sign of trust and autonomy. In
                our work, we use methods like research and experiments to see if a feature truly expands the user
                experience and upgrades the product. All this can be transposed to the company as a living laboratory,
                and we’re happy to say that every possible indicator is in the green.</p>

            <blockquote class="quote-solid-line">
                <p class="bold-quote">There will always be people who abuse perks given to them, but to be honest, I
                    think more people use fewer vacation days this way than they would if they had a 25-day vacation
                    cap.</p>
                <p>– Tamara Miliša, iOS Developer</p>
            </blockquote>

            <p>Unlimited vacation is not to be confused with a reward system, far from it. This is about something else.
                Vacation time is a right, not a reward. People are not mindless automatons and need to unplug from work
                – mentally and physically. And then when they do come back, they come back refreshed, and work makes
                sense again.</p>

            <blockquote class="quote-solid-line">
                <p class="bold-quote">I think it also attributes to the work-life balance because I can create a clear
                    distinction between work and non-work time by taking enough time to gain mental rest.</p>
                <p>– Ivan Lesar, Backend Developer</p>
            </blockquote>

            <p>To answer the question posed at the beginning of this story, if you’re fostering a culture of endless
                hustle, in all probability, you’ll breed tired, overworked, demotivated hustlers instead of a thriving,
                innovative and engaged workforce.</p>

            <p>With that in mind, unlimited vacation doesn’t work for everyone. This needs to be said loud and clear.
                But if you hire the right people and build a sense of ownership, vacation is more of a pause than an
                escape.</p>
        </template>
    </BlogPostLayout>
</template>

<script>

import blogMetadata from '@/util/blogMetadata'
import BlogPostLayout from '@/components/blog/BlogPostLayout.vue'
import authors from '@/views/blog/posts/authors'

export default {
    name: 'HowMoreDowntimeDrivesCreativityInnovation',

    metaInfo: blogMetadata.howMoreDowntimeDrivesCreativity,

    props: {},

    data: function () {
        return {
            tags: ['Culture', 'Unlimited vacation', 'Work-life balance', 'Freedom & responsibility'],
            meta: {
                authors: [authors.vatroslavMilos, authors.zvonimiraMilevcic],
                // visualsBy: authors.tenaPezdevsek,
                publishedDate: '16 jan 2023.'
            }
        }
    },

    created() {

    },

    components: {BlogPostLayout},

    computed: {},

    methods: {}
}
</script>
